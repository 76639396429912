import commerceConfig from '@commerce/config'
import Image from 'next/legacy/image'
import { FC } from 'react'

interface props {
  items: {
    logo: {
      dimensions: {
        width: number
        height: number
      }
      url: string
      alt: string
    }
  }[]
  primary: {
    background_colour: string
    padding_top: boolean
    section_title: {
      text: string
    }[]
    title_colour: string
  }
}

const LogoBlock: FC<props> = ({ primary, items }) => {
  const { background_colour, padding_top, section_title, title_colour } = primary
  const {
    config: { defaultAlt },
  } = commerceConfig
  return (
    <section
      className={`logo-block pb-40 laptop:pb-80${padding_top ? ' pt-40 laptop:pt-80' : ''}`}
      style={{ backgroundColor: background_colour }}
    >
      <div className="wrapper">
        {section_title[0].text && (
          <div className="title mb-40 text-center" style={{ color: title_colour }}>
            {section_title[0].text}
          </div>
        )}
        {items.length > 0 && (
          <div className="flex justify-center flex-wrap">
            {items.map((item, i) => (
              <div key={i} className="logo-block__logo mb-28 mx-20">
                <Image
                  src={item.logo.url}
                  width={item.logo.dimensions.width / 2}
                  height={item.logo.dimensions.height / 2}
                  alt={item.logo.alt || defaultAlt}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  )
}

export default LogoBlock
